html,
body {
  background: #0a0a0a;
}

/* desktop */
@media screen and (min-width: 769px) {
  html,
  body {
    ::-webkit-scrollbar {
      width: 0px !important;
      height: 4px !important;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: #232320;
    }
  }

  @-moz-document url-prefix() {
    body {
      scrollbar-width: thin;
      scrollbar-color: #232320 transparent;
    }
  }

  /* tamagui reset.css removes outlines for <button> tags */
  /* which broke the outline for focusing a button while tabbing through  */
  button:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

/* mobile */
@media screen and (max-width: 768px) {
  /* hide scrollbars */
  html,
  body {
    ::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
    }
  }
  @-moz-document url-prefix() {
    body {
      scrollbar-width: thin;
      scrollbar-color: #232320 transparent;
    }
  }
}

#privy-dialog-backdrop {
  backdrop-filter: none !important;
  -webkit-backdrop-filter: none !important;
  transition: none !important;
  background-color: #ffffff12 !important;
}

#privy-dialog-title {
  font-family: 'GT Standard Heading';
  letter-spacing: 1.6px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 22px;
}

/* privy overrides */
:root {
  --privy-border-radius-sm: 2px !important;
  --privy-border-radius-md: 2px !important;
  --privy-border-radius-lg: 2px !important;
  --privy-border-radius-mdlg: 2px !important;
  --privy-border-radius-full: 2px !important;
  --privy-color-background: #0a0a0a !important;
  --privy-color-background-2: #0a0a0a !important;
  --privy-color-foreground: #e9e8e2 !important;
  --privy-color-foreground-2: #96958f !important;
  --privy-color-foreground-3: #65645f !important;
  --privy-color-foreground-4: #232320 !important;
  --privy-color-foreground-accent: #232320 !important;
  --privy-color-accent-light: #232320 !important;
  --privy-color-accent: #f63e58 !important;
  --privy-color-success: #f63e58 !important;
  --privy-color-error: #f63e58 !important;
}

@font-face {
  font-family: 'GT Standard Mono';
  src: url('/fonts/GT-Standard-Mono-Beta-V2-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: '100 900';
}

@font-face {
  font-family: 'GT Standard Mono Bold';
  src: url('/fonts/GT-Standard-Mono-Beta-V2-Medium.otf') format('opentype');
  font-style: normal;
  font-weight: '100 900';
}

@font-face {
  font-family: 'GT Standard Display';
  src: url('/fonts/GT-Standard-Beta-V6-Regular-L.otf') format('opentype');
  font-style: normal;
  font-weight: '100 900';
}

@font-face {
  font-family: 'GT Standard Heading';
  src: url('/fonts/GT-Standard-Beta-V6-Regular-M.otf') format('opentype');
  font-style: normal;
  font-weight: '100 900';
}

@font-face {
  font-family: 'GT Standard';
  src: url('/fonts/GT-Standard-Beta-V6-Light-M.otf') format('opentype');
  font-style: normal;
  font-weight: '100 900';
}
